.searchOnFocus {
  width: 100%;
  transition: width .8s;
}
.searchOnBlur {
  width: 30%;
  transition: width .8s;
}
.titleOnFocus {
  width: 0;
  overflow: hidden;
  transition: width .8s;
}

.titleOnBlur {
  width: 60%;
  overflow: hidden;
  transition: width .8s;
}
.detail-card {
  .MuiCardHeader-content {
    width: 70%;
  }
}